import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocale } from "../hooks/locale";
import favicon from "../images/favicon.ico";
import config from "../../config/i18n";

function SEO({ description, meta, title, image, author, pathname, keywords }) {
  const { locale } = useLocale();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description;
  const metaKeywords = keywords || config[locale].keywords;
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;
  const url = site.siteMetadata.siteUrl;
  const ogImage = image ? `${url}${image}` : `${url}/images/rezzii_www.jpg`; //default image

  // add rtl support - Arabic is Right to Left Language
  // change ar with your RTL language
  const dir = locale === "ar" ? "rtl" : "ltr";

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
        dir,
      }}
      title={metaTitle}
      titleTemplate={metaDescription ? `%s - ${metaDescription}` : `%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image:src`,
          content: ogImage,
        },
        {
          name: `twitter:creator`,
          content: author || site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <link rel="canonical" key={canonical} href={canonical} />
      <link rel="icon" href={favicon} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
